import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Photo from '../../../components/daniel';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`About`}</h2>
    <Photo mdxType="Photo" />
    <blockquote>
      <p parentName="blockquote">{`Hi, I'm Daniel - founder of Pristine Bytes. I craft Atlassian add-ons with passion for usability and design since early 2019. Operating out of southern Germany I strive to make your work more joyous and less complicated. `}</p>
    </blockquote>
    <p>{`My Atlassian add-ons are helping more than 70 organisations to get the most out of Confluence and Jira. Maybe your company will be next? With over 10 years of experience in agile software development i hope to be a reliable partner in your endeavors.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      