/** @jsx jsx */
import React from "react"
import {jsx} from "theme-ui"

type ProjectCardProps = {
  link: string
  title: string
  children: React.ReactNode
  bg: string,
  logo: string,
  logoBg: string
}

const AppCard = ({link, title, children, bg, logo, logoBg}: ProjectCardProps) => (
    <a href={link} target="_blank" rel="noreferrer noopener" sx={{
      width: `100%`,
      overflow: 'hidden',
      boxShadow: `lg`,
      position: `relative`,
      textDecoration: `none`,
      borderRadius: `lg`,
      px: 4,
      py: [4, 5],
      color: `white`,
      background: bg || `none`,
      transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
      "&:hover": {
        color: `white !important`,
        transform: `translateY(-5px)`,
        boxShadow: `xl`,
      },
    }}>
      <div sx={{opacity: 0.75, textShadow: `0 2px 10px rgba(0, 0, 0, 0.3)`}}>
        <div style={{
          position: "absolute",
          zIndex: -1,
          top: "-20%",
          right: "-15%",
          width: "110%",
          height: "110%",
          background: logoBg,
          WebkitMaskImage: "url('" + logo + "')",
          maskImage: "url('" + logo + "')",
          WebkitMaskRepeat: "no-repeat",
          maskRepeat: "no-repeat",
          WebkitMaskPosition: 'right',
          maskPosition: 'right'
        }}/>

        {children}

        <div sx={{
          textTransform: `uppercase`,
          letterSpacing: `wide`,
          pt: 4,
          fontSize: [4, 5],
          fontWeight: `medium`,
          lineHeight: 1,
        }}>
          {title}
        </div>
      </div>
    </a>
)

export default AppCard
