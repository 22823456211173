/** @jsx jsx */
import {jsx} from 'theme-ui'

const Photo = () => (
    <img src="./daniel.jpeg" alt="The Founder Daniel" sx={{
      mt: '30px',
      mr: '25px',
      ml: '5px',
      boxSizing: 'content-box',
      borderRadius: '50%',
      width: '150px',
      border: '3px solid white',
      float: 'left'}}/>
)

export default Photo
