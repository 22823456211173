import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say `}<a parentName="p" {...{
        "href": "mailto:support@pristinebytes.com"
      }}>{`Hi`}</a>{`, report an `}<a parentName="p" {...{
        "href": "https://pristinebytes.atlassian.net/servicedesk/customer/portal/1"
      }}>{`issue`}</a>{`  or get `}<a parentName="p" {...{
        "href": "https://pristinebytes.atlassian.net/servicedesk/customer/portal/1"
      }}>{`help`}</a>{` if you got stuck.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      