/** @jsx jsx */
import {jsx} from 'theme-ui'

const Logo = () => (
    <img src="marketplace-white.svg" alt="Atlassian Marketplace" sx={{
      verticalAlign: 'middle',
      height: ['40px', '40px', '60px'],
      position: 'relative',
      bottom: ['0px', '0px', '3px']
    }}/>
)

export default Logo
