import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import MarketplaceLogo from '../../../components/MarketplaceLogo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Hey there, you just found`}</p>
    <h1>{`Pristine Bytes`}</h1>
    <p>{`Source of stellar Jira and Confluence Cloud add-ons on the `}<MarketplaceLogo mdxType="MarketplaceLogo" /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      