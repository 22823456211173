/** @jsx jsx */
import {Flex, Footer as ThemeFooter, jsx, Styled} from "theme-ui"

const Footer = () => {
  return (
      <ThemeFooter>
        <Flex sx={{
          justifyContent: `center`,
          alignItems: `center`,
          flexWrap: 'wrap',
          mt: 3,
          color: `text`,
          fontWeight: `semibold`,
          a: {color: `text`},
        }}>
          <Styled.a sx={{m: 2}} href="https://documentation.pristinebytes.com">Documentation</Styled.a>
          <Styled.a sx={{m: 2}} href="/privacy-policy">Privacy Policy</Styled.a>
          <Styled.a sx={{m: 2}} href="/licence-agreement">Licence Agreement</Styled.a>
          <Styled.a sx={{m: 2}} href="/impressum">Impressum</Styled.a>
        </Flex>

        &copy; {new Date().getFullYear()} Pristine Bytes
      </ThemeFooter>
  )
}

export default Footer
