/** @jsx jsx */
import {jsx} from "theme-ui"
import Super from "@lekoarts/gatsby-theme-cara/src/components/hero"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"

const Hero = ({offset}: { offset: number }) => <div>
  <Super offset={offset}/>
  <Divider speed={0} offset={offset}>
    <img src="logo-with-text.svg" css={{margin: "30px", width: "400px"}}/>
  </Divider>
</div>

export default Hero
